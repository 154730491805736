export interface TariffMappingEntity{

    id: number | null,
    priority: number | null,
    tempPriority: number | null,
    chargeType?: string | null,
    chargeOnCompanyCode?: string | null,
    consortiumCode?: string | null,
    physicalHandlingCode?: string | null,
    modalityOfArrival?: string | null,
    modalityOfDeparture?: string | null,
    overWeightInd?: string | null,
    overDimensionInd?: string | null,
    bundleInd?: string | null,
    cntrStatus?: string | null,
    specialHandlingInd?: string | null,
    legSpHandlingInd?: string | null,
    serviceCode?: string | null,
    cntrSize?: string | null,
    cntrType?: string | null,
    directInd?: string | null,
    specialInd?: string | null,
    chargeInd?: string | null,
    tsLegChargeInd?: string | null,
    subChargeType?: string | null,
    loaLimitFrom?: number | null,
    loaLimitTo?: number | null,
    reeferHandlingType?: string | null,
    rcType?: string | null,
    marshallingType?: string | null,
    marshallingFrom?: string | null,
    marshallingTo?: string | null,
    fromTml?: string | null,
    toTml?: string | null,
    serviceOrderCode?: string | null,
    overWeightFrom?: number | null,
    overWeightTo?: number | null,
    shipmentType?: string | null,
    nonCellularVslInd?: string | null,
    wireSlingInd?: string | null,
    palletizedInd?: string | null,
    cargoType?: string | null,
    cargoCode?: string | null,
    shiftCodeOps?: string | null,
    cntrTypeGroup?: string | null,
    specialArea?: string | null,
    roundTripFlag?: string | null,
    opsLine?: string | null,
    forwarderCode?: string | null,
    forwarderRef?: string | null,
    equipmentCodeInd?: string | null,
    emptyIndicator?: string | null,
    partnerCode?: string | null,
    cntrTspStatus?: string | null,
    operationType?: string | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    activeInd: string | null,
    mappingType: string | null,

    [key: string]: string | boolean | number | null | Object | undefined
}

export const EMPTY_TARIFF_MAPPING_ENTITY : TariffMappingEntity = {
    id: 0,
    priority: null,
    tempPriority: null,
    chargeType: '',
    chargeOnCompanyCode: '',
    consortiumCode: '',
    physicalHandlingCode: '',
    modalityOfArrival: '',
    modalityOfDeparture: '',
    overWeightInd: '',
    overDimensionInd: '',
    bundleInd: '',
    cntrStatus: '',
    specialHandlingInd: '',
    legSpHandlingInd: '',
    serviceCode: '',
    cntrSize: '',
    cntrType: '',
    directInd: '',
    specialInd: '',
    chargeInd: '',
    tsLegChargeInd: '',
    subChargeType: '',
    loaLimitFrom: null,
    loaLimitTo: null,
    reeferHandlingType: '',
    rcType: '',
    marshallingType: '',
    marshallingFrom: '',
    marshallingTo: '',
    fromTml: '',
    toTml: '',
    serviceOrderCode: '',
    overWeightFrom: null,
    overWeightTo: null,
    shipmentType: '',
    nonCellularVslInd: '',
    wireSlingInd: '',
    palletizedInd: '',
    cargoType: '',
    cargoCode: '',
    shiftCodeOps: '',
    cntrTypeGroup: '',
    specialArea: '',
    roundTripFlag: '',
    opsLine: '',
    forwarderCode: '',
    forwarderRef: '',
    equipmentCodeInd: '',
    emptyIndicator: '',
    partnerCode: '',
    cntrTspStatus: '',
    operationType: '',
    tariffType: '',
    tariffCode: '',
    activeInd: 'Y',
    mappingType: "TariffMappingEntity",
}
