import { ConsortiumRepoImpl } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CntrTypeGroupRepoImpl } from "domain/repository/CntrTypeGroup/CntrTypeGroupRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { EquipmentCodeIndMappingRepoImpl } from "domain/repository/EquipmentCodeIndMapping/EquipmentCodeIndMappingRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { ShiftRepoImpl } from "domain/repository/Shift/ShiftRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { SpecialIndRepoImpl } from "domain/repository/SpecialInd/SpecialIndRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffMappingRepoImpl } from "domain/repository/TariffMapping/TariffMappingRepoImpl";
import { useTariffMappingTracked } from "presentation/store/TariffMapping/TariffMappingProvider";
import { TariffMappingVM } from "presentation/viewModel/TariffMapping/TariffMappingVM";
import { useMemo } from "react";

export const useTariffMappingVM = () => {
    const [, setTariffMappingState] = useTariffMappingTracked();
    const tariffMappingVM = useMemo(() =>
        TariffMappingVM({
            dispatch: [setTariffMappingState],
            tariffMappingRepo: TariffMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            consortiumRepo: ConsortiumRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            cntrTypeGroupRepo: CntrTypeGroupRepoImpl(),
            specialIndRepo: SpecialIndRepoImpl(),
            equipmentCodeIndMappingRepo: EquipmentCodeIndMappingRepoImpl(),
            shiftRepo: ShiftRepoImpl(),
        }), [setTariffMappingState])

    return tariffMappingVM
}