import { TariffMappingEntity } from "domain/entity/TariffMapping/TariffMappingEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { TariffMappingRepository } from "./TariffMappingRepo";

export const TariffMappingRepoImpl = (): TariffMappingRepository => {
    
    const searchUrl = "/v1/searchAllTariffMappingList"

    const searchAllTariffMappingList = async() : Promise<TariffMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: TariffMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/tariffMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: TariffMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/tariffMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }

    const uploadData =async(csvData: TariffMappingEntity[]): Promise<ResponseEntity> => {
        const uploadUrl = "/v1/tariffMappingUpload"
        return await axiosPostData(referenceAxiosInstance, `${uploadUrl}`, csvData);
    }

    return {
        uploadData: uploadData,
        onSave: onSave,
        onApply: onApply,
        searchAllTariffMappingList : searchAllTariffMappingList,
    }
}
