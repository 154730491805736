
export const TariffMappingConstant = {
    Table: {
        PRIORITY: "Priority",
        CHARGE_TYPE: "Charge Type",
        CHARGEON_COMPANY: "Charge-on Company",
        CONSORTIUM_CODE: "Consortium Code",
        CONTAINER_PHYSICAL_HANDLING_CODE: "Container Physical Handling Code",
        MODALITY_OF_ARRIVAL: "Modality of Arrival",
        MODALITY_OF_DEPARTURE: "Modality of Departure",
        OVER_WIDTH_IND: "Over Width Ind.",
        OVER_DIMENSION_IND: "Over Dimension Ind.",
        BUNDLE_IND: "Bundle Ind.",
        STATUS: "Status",
        SP_HANDLING_IND: "SP Handling Ind.",
        SP_HANDLING_IND_D: "SP Handling Ind. (D1)",
        SERVICE_CODE: "Service Code",
        SIZE: "Size",
        TYPE: "Type",
        DIRECT_DISCHARGE_IND: "Direct Discharge Ind.",
        SPECIAL_IND: "Special Ind.",
        CHARGE_IND: "Charge Ind.",
        TRANSHIPMENT_LEG_CHARGE_IND: "Transhipment Leg Charge Ind.",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        LOA_LIMIT_FROM: "LOA Limit From",
        LOA_LIMIT_TO: "LOA Limit To",
        REEFER_HANDLING_TYPE: "Reefer Handling Type",
        REHANDLING_CANCEL_SHIP_TYPE: "Rehandling Cancel Ship Type",
        MARSHALLING_TYPE: "Marshalling Type",
        MARSHALLING_FROM: "Marshalling From",
        MARSHALLING_TO: "Marshalling To",
        FROM_TML: "From Tml",
        TO_TML: "To Tml",
        SERVICE_ORDER_CODE: "Service Order Code",
        OVER_WEIGHT_FROM: "Over Weight From",
        OVER_WEIGHT_TO: "Over Weight To",
        SHIPMENT_TYPE: "Shipment Type",
        NONCELLULAR_VSL_IND: "Non-Cellular Vsl Ind.",
        WIRE_SLING_IND: "Wire Sling Ind.",
        PALLETIZED_IND: "Palletized Ind.",
        CARGO_TYPE: "Cargo Type",
        CARGO_CODE: "Cargo Code",
        SHIFT_CODEOPS: "Shift Code(Ops)",
        CNTR_TYPE_GROUP: "Cntr Type Group",
        SPECIAL_AREA: "Special Area",
        ROUND_TRIP: "Round Trip",
        OPS_LINE: "Ops Line",
        FORWARDER_CODE: "Forwarder Code",
        FORWARDER_REF: "Forwarder Ref",
        EQUIPMENT_CODE_IND: "Equipment Code Ind.",
        EMPTY_INDICATOR: "Empty Indicator",
        PARTNER_CODE: "Partner Code",
        TRANSPORTATION_STATUS: "Transportation Status",
        OPERATION_TYPE: "Operation Type",
        TARIFF_TYPE: "Tariff Type",
        TARIFF_CODE: "Tariff Code",
        ACTIVE_IND: "Active Ind.",
    },
    Title: {
        CATEGORY: "Tariff",
        TITLE: "Tariff Setting",
        CHARGE: "Charge",
        VESSEL: "Vessel",
        ADDITIONAL_INFO: "Additional Info",
        INDIRECT_INFO: "Indirect Info",
        BILLING_TYPE: "Billing Type",
        MAPPING_FROM: "From",
        MAPPING_TO: "To",
    },
}
export const tariffMappingRequiredFieldList: string[] = [
    'tariffType',
    'tariffCode',
    'chargeType',
    'activeInd',
];

export const tariffMappingCSVColumnMapping: string[] = [
    'chargeType',
    'chargeOnCompanyCode',
    'consortiumCode',
    'physicalHandlingCode',
    'modalityOfArrival',
    'modalityOfDeparture',
    'overWeightInd',
    'overDimensionInd',
    'cntrStatus',
    'specialHandlingInd',
    'serviceCode',
    'cntrSize',
    'cntrType',
    'directInd',
    'specialInd',
    'chargeInd',
    'tsLegChargeInd',
    'subChargeType',
    'loaLimitFrom',
    'loaLimitTo',
    'reeferHandlingType',
    'rcType',
    'marshallingType',
    'marshallingFrom',
    'marshallingTo',
    'fromTml',
    'toTml',
    'shipmentType',
    'nonCellularVslInd',
    'wireSlingInd',
    'palletizedInd',
    'cargoType',
    'cargoCode',
    'shiftCodeOps',
    'cntrTypeGroup',
    'tariffType',
    'tariffCode',
    'specialArea',
    'opsLine',
    'forwarderCode',
    'forwarderRef',
    'equipmentCodeInd',
    'roundTripFlag',
    'activeInd',
    'operationType',
    'cntrTspStatus',
];