import { useTariffMappingVM } from "presentation/hook/TariffMapping/useTariffMappingVM";
import { useTariffMappingTracked } from "presentation/store/TariffMapping/TariffMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffMappingTitleBar from "./TariffMappingTitleBar";
import TariffMappingEditPanel from "./RightPanel/TariffMappingEditPanel";
import TariffMappingTablePanel from "./Table/TariffMappingTablePanel";


const TariffMappingMaintenance:React.FC = () => {
    const [tariffMappingState] = useTariffMappingTracked();
    const [isLoading, setIsLoading] = useState(true);
    const tariffMappingVM = useTariffMappingVM();
    const {isShowEditPanel} = tariffMappingState;


    useEffect(() => {
        const initialScreen = async() => {   
            try {     
                const results = await Promise.allSettled([
                    tariffMappingVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {            
            tariffMappingVM.searchAllTariffMappingList().then((data) => {            
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [tariffMappingVM])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
        
        {!(isShowEditPanel)&&<>
            <TariffMappingTitleBar/>
            <TariffMappingTablePanel/></>}
        {(isShowEditPanel)&&
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"100%"}
                rightSectionWidth={"0%"}
                leftChildren={<TariffMappingEditPanel/>}
                />}
        </div>
    </>
}

export default memo(TariffMappingMaintenance);