import { TariffMappingConstant } from "presentation/constant/TariffMapping/TariffMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useTariffMappingVM } from "presentation/hook/TariffMapping/useTariffMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffMappingTitleBar:React.FC = () => {
    const TARIFF_MAPPING_CONSTANT= TariffMappingConstant.Title;
    const tariffMappingVM = useTariffMappingVM();
    // const [tariffMappingState] = useTariffMappingTracked();
    const [isLoading, setIsLoading] = useState(false);
    // const {updatedRows} = tariffMappingState;
    const messageBarVM = useMessageBarVM();  
    
    const handleAdd = useCallback(() => {
        tariffMappingVM.onAddClick();
    }, [tariffMappingVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await tariffMappingVM.onApply(updatedRows);
    //     setIsLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await tariffMappingVM.searchAllTariffMappingList();
    //     }
    //     setIsLoading(false);
    // }, [tariffMappingVM, messageBarVM, updatedRows]);

    
    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     tariffMappingVM.searchAllTariffMappingList().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[tariffMappingVM]);

    const handleUpload = useCallback(async (e:any) => {

        const files = e.target?.files;
        const file = files[0];

        if(!file){
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showWarining('Please upload a .csv file');
            return
        }
        
        setIsLoading(true);
        const res = await tariffMappingVM.onUpload(file);
        
        file.current.value = null;
        e.target = null;

        if(res.success){
            messageBarVM.showSuccess("Success!");
        }else{
            messageBarVM.showError(res.data);
        }

        setIsLoading(false);


        tariffMappingVM.searchAllTariffMappingList();

    }, [messageBarVM, tariffMappingVM]);

    // const isApplyDisable = () => {
    //     if(updatedRows.length !== 0) return false
    //     return true;
    // }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{TARIFF_MAPPING_CONSTANT.TITLE}</HeaderTitle>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/> */}
            {/* <div className="add-seperator"/> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            <div className="add-seperator"/>
            <FileUpload handleUpload={handleUpload} label={WorkspaceConstant.Common.BUTTON_UPLOAD} accept=".csv" size={"Small"} theme={"Primary"} disabled={false}/>
            {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffMappingTitleBar);