import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffMappingProvider } from "presentation/store/TariffMapping/TariffMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import TariffMappingMaintenance from "presentation/view/section/TariffMapping/TariffMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffMappingProvider>
                <GridStyles/>
                <TariffMappingMaintenance/>
            </TariffMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffMappingContMain;