import { CntrTypeGroupEntity } from "domain/entity/CntrTypeGroup/CntrTypeGroupEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { CntrTypeGroupRepository } from "./CntrTypeGroupRepo";

export const CntrTypeGroupRepoImpl = (): CntrTypeGroupRepository => {
    
    const searchUrl = "/v1/getCntrTypeGroupForComboBox"

    const getCntrTypeGroupForComboBox = async() : Promise<CntrTypeGroupEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }


    return {
        getCntrTypeGroupForComboBox : getCntrTypeGroupForComboBox,
    }
}
