import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EquipmentCodeIndMappingEntity } from "domain/entity/EquipmentCodeIndMapping/EquipmentCodeIndMappingEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { EquipmentCodeIndMappingRepository } from "./EquipmentCodeIndMappingRepo";

export const EquipmentCodeIndMappingRepoImpl = (): EquipmentCodeIndMappingRepository => {
    
    const searchUrl = "/v1/searchAllEquipmentCodeIndMappingList"

    const searchAllEquipmentCodeIndMappingList = async() : Promise<EquipmentCodeIndMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const getEquipmenyCodeComboBox = async() : Promise<EquipmentCodeIndMappingEntity[]> => {
        const getUrl = "/v1/getEquipmenyCodeComboBox"
        return axiosGetData(referenceAxiosInstance,`${getUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: EquipmentCodeIndMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/equipmentCodeIndMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: EquipmentCodeIndMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/equipmentCodeIndMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }


    return {
        onSave: onSave,
        onApply: onApply,
        getEquipmenyCodeComboBox: getEquipmenyCodeComboBox,
        searchAllEquipmentCodeIndMappingList : searchAllEquipmentCodeIndMappingList,
    }
}
